import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Input, Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import * as Yup from 'yup';
import Link from 'next/link';
import Layout from '@/layout'
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { getError } from '@/utils/error';
import { enqueueSnackbar } from 'notistack';
import { BsFacebook } from 'react-icons/bs'
import { AiFillGithub, AiOutlineGoogle, AiOutlineTwitter, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai'
import { motion } from "framer-motion";


const Login = () => {
  // FRAMER-MOTION
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  // FRAMER-MOTION

  const router = useRouter();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Email field required')
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'Invalid email format'
        ),
      password: Yup.string().required('Password field required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const result = await signIn('credentials', {
          redirect: false,
          email: values.email,
          password: values.password,
        });
        if (result.error) {
          enqueueSnackbar(result.error, {
            variant: 'default',
          });
        } else {
          enqueueSnackbar('You are logged in.', {
            variant: 'default',
          });
          router.push('/');
        }
      } catch (err) {
        enqueueSnackbar(getError(err), {
          variant: 'default',
        });
      }
      resetForm();
    },
  });

  async function handleGithubSignIn() {
    signIn('github', { callbackUrl: "https://oxfordpte.com" })
  }
  async function handleGoogleSignIn() {
    signIn('google', { callbackUrl: "https://oxfordpte.com" })
  }
  async function handleFacebookSignIn() {
    signIn('facebook', { callbackUrl: "https://oxfordpte.com" })
  }
  async function handleTwitterSignIn() {
    signIn('twitter', { callbackUrl: "https://oxfordpte.com" })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout
      title="Login account"
      description="You will become a member of Oxford PTE after logging in, and you will then be able to purchase PTE materials and enroll in our live classes."
    >
      <div className='prose max-w-none flex flex-col gap-5 items-center w-full sm:w-80 justify-center mt-20 mb-24 px-4 sm:px-6 lg:px-8 container mx-auto'>
        <Card>
          <CardBody>
            <CardHeader className='flex flex-col gap-2'>
              <h3 className='m-0 dark:text-foreground'>Sign in</h3>
              <p className='m-0 text-sm text-center'>New to Oxford PTE? <Link href="/register" className='text-blue-600'>Create an account</Link></p>
            </CardHeader>
            <motion.div
              variants={container}
              initial="hidden"
              animate="visible"
              className="flex items-center justify-center gap-4 mb-4 mt-2"
            >
              <motion.div className="item" variants={item}>
                <Button isIconOnly  onClick={handleGoogleSignIn} className="rounded bg-[#ea4335] hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
                  <AiOutlineGoogle size={24} color='#fff'/>
                </Button>
              </motion.div>
              <motion.div className="item" variants={item}>
                <Button isIconOnly onClick={handleFacebookSignIn} className="rounded bg-[#1DA1F2] hover:shadow-[#1DA1F2]/20 focus:shadow-[#1DA1F2]/20 active:shadow-[#1DA1F2]/10">
                  <BsFacebook size={24} color='#fff'/>
                </Button>
              </motion.div>
              <motion.div className="item" variants={item}>
                <Button isIconOnly onClick={handleTwitterSignIn} className="rounded bg-[#1DA1F2] hover:shadow-[#1DA1F2]/20 focus:shadow-[#1DA1F2]/20 active:shadow-[#1DA1F2]/10">
                  <AiOutlineTwitter size={24} color='#fff'/>
                </Button>
              </motion.div>
              <motion.div className="item" variants={item}>
                <Button isIconOnly onClick={handleGithubSignIn} className='bg-gray-800 rounded'>
                  <AiFillGithub size={24} color='#fff' />
                </Button>
              </motion.div>
            </motion.div>
            <div className='relative border-b border-gray-300 w-full md:w-80 flex items-center justify-center mb-5'>
              <span className='absolute -top-4 p-1.5 rounded-[50%] text-sm text-gray-500 bg-white dark:bg-ox-black dark:text-ox-dark-text'>or</span>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-4"
            >
              <div>
                <Input
                  isRequired
                  placeholder="Enter your email"
                  label="Email"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full dark:text-ox-dark-text"
                />
                {formik.touched.email && formik.errors.email && (
                  <small className="pt-1 text-ox-red text-xs">
                    {formik.errors.email}
                  </small>
                )}
              </div>
              <div>
                <Input
                  isRequired
                  placeholder="Enter your password"
                  label="Password"
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full dark:text-ox-dark-text"
                />
                {formik.touched.password && formik.errors.password && (
                  <small className="pt-1 text-ox-red text-xs">
                    {formik.errors.password}
                  </small>
                )}
              </div>
              <Link href="/reset-password" className='w-full text-right m-0 text-sm text-blue-600 cursor-pointer'>Forget password?</Link>
              <Button type="submit" variant='solid' color='primary' radius="full">
                Log in
              </Button>
            </form>
          </CardBody>
        </Card>
      </div>
    </Layout>
  );
};

export default Login;
